import { render, staticRenderFns } from "./BackgroundVideo.vue?vue&type=template&id=6d6b45c2&scoped=true&"
import script from "./BackgroundVideo.vue?vue&type=script&lang=js&"
export * from "./BackgroundVideo.vue?vue&type=script&lang=js&"
import style0 from "./BackgroundVideo.vue?vue&type=style&index=0&id=6d6b45c2&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d6b45c2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CeMediaFile: require('/home/rof/src/bitbucket.org/medienagenten/krauss-der-stein-nuxtjs/components/content/elements/CeMediaFile.vue').default})
