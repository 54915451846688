//
//
//
//
//
//
//
//

import qs from 'querystring'

export default {
  name: 'BackgroundVideo',
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    videoAllowed() {
      return this.$store.state.cookiebot.preferences
    },
  },
  mounted() {
    this.calculateBackground()

    window.addEventListener('resize', this.resize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    prepared(file) {
      switch (file.properties.type) {
        case 'video':
          if (file.properties.mimeType.includes('youtube')) {
            return {
              ...file,
              publicUrl: this.createYouTubeUrl(file),
            }
          } else if (file.properties.mimeType.includes('vimeo')) {
            return {
              ...file,
              publicUrl: this.createVimeoUrl(file),
            }
          } else {
            return file
          }
        default:
          return file
      }
    },
    resize() {
      this.calculateBackground()
    },
    calculateBackground() {
      const scale = Math.max(
        this.$el.offsetWidth / this.$refs.video.offsetWidth,
        this.$el.offsetHeight / this.$refs.video.offsetHeight
      )
      this.$refs.video.style.transform = `translate(-50%, -50%) scale(${scale})`
    },
    createVimeoUrl(file) {
      return (
        file.publicUrl.split('?')[0] +
        '?' +
        qs.stringify({
          title: 0,
          byline: 0,
          portrait: 0,
          dnt: 1,
          autoplay: 1,
          background: 1,
          loop: 1,
        })
      )
    },
    createYouTubeUrl(file) {
      return (
        file.publicUrl.split('?')[0] +
        '?' +
        qs.stringify({
          autohide: 1,
          modestbranding: 1,
          showinfo: 0,
          rel: 0,
          controls: 0,
          autoplay: 1,
          loop: 1,
          mute: 1,
          fs: 1,
        })
      )
    },
  },
}
