//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import qs from 'querystring'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import baseCe from '../mixins/baseCe'

export default {
  name: 'CeHero',
  components: {
    Swiper,
    SwiperSlide,
  },
  extends: baseCe,
  props: {
    heroblockitems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        loop: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  methods: {
    parseVideoData(videoData) {
      return Object.assign(videoData, {
        publicUrl: this.createVimeoUrl(videoData),
      })
    },
    createVimeoUrl(file) {
      return (
        file.publicUrl.split('?')[0] +
        '?' +
        qs.stringify({
          title: 0,
          byline: 0,
          portrait: 0,
          dnt: 1,
          autoplay: 1,
          background: 1,
          loop: 1,
        })
      )
    },
    prev() {
      this.swiper.slidePrev()
    },
    next() {
      this.swiper.slideNext()
    },
    convertToClearNumber(num) {
      return num.replace(/[^0-9&.]/g, '')
    },
    convertRemToPixels(rem) {
      return (
        parseFloat(this.convertToClearNumber(rem)) *
        (typeof document !== 'undefined'
          ? parseFloat(getComputedStyle(document.documentElement).fontSize)
          : 16)
      )
    },
  },
}
