// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\n.hero_asY6G{\n  position:relative;\n  overflow:hidden;\n}\n.hero_asY6G .shadow_CbJCV, .hero_asY6G .text_AXym-, .hero_asY6G img{\n    position:absolute;\n    top:0px;\n    right:0px;\n    bottom:0px;\n    left:0px\n}\n.hero_asY6G .slide_WLbk9{\n    position:relative;\n    width:100vw;\n    overflow:hidden;\n    height:calc(100vh - 70px);\n}\n@media (min-width: 1024px){\n.hero_asY6G .slide_WLbk9{\n      height:calc(100vh - 110px)\n}\n}\n.hero_asY6G .text_AXym-{\n    --tw-text-opacity:1;\n    color:rgba(255, 255, 255, var(--tw-text-opacity));\n    left:10%;\n    top:10%\n}\n.hero_asY6G .shadow_CbJCV{\n    background:linear-gradient(135deg,rgba(0,0,0,.65),transparent 55%,transparent 99%)\n}\n.hero_asY6G img{\n    height:100%;\n    width:100%;\n    -o-object-fit:cover;\n       object-fit:cover\n}\n.hero_asY6G .link_e3POO{\n    padding:1rem;\n    padding-left:0px;\n}\n.hero_asY6G .link_e3POO:before{\n      font-family:swiper-icons;\n      display:inline-block;\n      content:\"next\";\n      line-height:1.8;\n      vertical-align:middle\n}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": "hero_asY6G",
	"shadow": "shadow_CbJCV",
	"text": "text_AXym-",
	"slide": "slide_WLbk9",
	"link": "link_e3POO"
};
module.exports = ___CSS_LOADER_EXPORT___;
